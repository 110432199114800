import Button, { LinkButton } from '@stories/Components/Buttons/Button/Button';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { imageUrl } from '@helpers/imageUrl';
import { LoginBannerStyles as S } from './LoginBanner.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron-right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import withRedux from '@helpers/withRedux';
import { withMotion } from '@hoc/withMotion';
import withWidget from '@hoc/withWidget';
import { withQueryParams } from '@hoc/withQueryParams';
import { HydrateOption } from '@core/enums';
const LoginBanner = ({ title, subTitle, text, image, loginText, registrationCta, }) => {
    const dispatch = useDispatch();
    const loggedIn = useSelector((x) => x.isLoggedIn);
    const [params] = useQueryParams({
        ReturnUrl: StringParam,
    });
    return (React.createElement(S.Container, null,
        React.createElement(S.LeftSection, null,
            React.createElement(S.MotionWrapper, null,
                React.createElement(S.Title, null, title)),
            !!subTitle && subTitle.length && (React.createElement(S.MotionWrapper, null,
                React.createElement(S.SubTitle, null, subTitle))),
            React.createElement(S.MotionWrapper, null,
                React.createElement(S.MobileImage, null,
                    React.createElement("img", { src: imageUrl(image.src, { width: 350, dpr: 1 }), sizes: '350px' }))),
            React.createElement(S.MotionWrapper, null,
                React.createElement(S.Text, { dangerouslySetInnerHTML: { __html: text } })),
            !loggedIn && (React.createElement(S.MotionWrapper, null,
                React.createElement(S.ButtonsContainer, null,
                    React.createElement(Button, { branding: "primaryAlt", title: loginText, onClick: () => dispatch({
                            type: 'APP/SET_LOGIN_MODAL',
                            payload: {
                                loginType: LoginRedirectType.Default,
                                redirectUrl: params.ReturnUrl || undefined,
                            },
                        }) }),
                    React.createElement(LinkButton, { href: registrationCta.url, branding: "textOnly", title: registrationCta.title, addon: React.createElement(Chevron, null), addonPosition: "right", target: "_blank" }))))),
        React.createElement(S.ImageContainer, null,
            React.createElement("img", { src: imageUrl(image.src, { width: 720, dpr: 1 }), sizes: '720px' }))));
};
export default withWidget(withRedux(withQueryParams(withMotion(LoginBanner))), 'LoginBanner', {
    hydrate: HydrateOption.InView,
});
