import React from 'react';
import { Route } from 'react-router';
import { QueryParamProvider } from 'use-query-params';
import { withRouter } from './withRouter';
export function withQueryParams(WrappedComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const ComponentWithQueryParams = (props) => {
        return (React.createElement(QueryParamProvider, { ReactRouterRoute: Route },
            React.createElement(WrappedComponent, { ...props })));
    };
    ComponentWithQueryParams.displayName = `withQueryParams(${displayName})`;
    return withRouter(ComponentWithQueryParams);
}
