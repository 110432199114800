import React from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
export function withRouter(WrappedComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const ComponentWithRouter = (props) => {
        if (typeof window === 'undefined') {
            // if (!props.location) {
            //   throw new Error(
            //     `The "location" prop was not provided when the "withRouter" HOC was used. Ensure the Tag Helper is using the "use-router" parameter.`,
            //   );
            // }
            return (React.createElement(StaticRouter, null,
                React.createElement(WrappedComponent, { ...props })));
        }
        return (React.createElement(BrowserRouter, { basename: props.basename },
            React.createElement(WrappedComponent, { ...props })));
    };
    ComponentWithRouter.displayName = `withRouter(${displayName})`;
    return ComponentWithRouter;
}
