import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import SiteWide from '@stories/Components/Containers/SiteWide/SiteWide';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled(SiteWide) `
  display: flex;
  flex-direction: column;
  padding: 15px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }

  @media ${from(Device.Desktop)} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 75px 0px;
  }
`;
const LeftSection = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    },
})) `
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${until(Device.Tablet)} {
    ${() => ParagraphStyles.Paragraph} {
      margin-bottom: 0;
    }
  }

  @media ${from(Device.Desktop)} {
    grid-column: 2 / 8;
  }
  @media ${from(Device.DesktopLarge)} {
    grid-column: 2 / 7;
  }
`;
const ButtonsContainer = styled.div `
  display: flex;
  gap: 15px;
  margin-top: 15px;

  @media ${until(Device.Desktop)} {
    flex-direction: column;
  }

  ${() => ButtonStyles.Button} {
    min-width: 150px;
    text-align: center;

    &:last-child {
      svg {
        max-height: 15px;
      }
    }
  }
`;
const ImageContainer = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    variants: {
        hidden: {
            x: 35,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
        },
    },
    transition: {
        duration: 1,
    },
})) `
  @media ${until(Device.Desktop)} {
    display: none;
  }

  max-height: 375px;

  @media ${from(Device.Desktop)} {
    grid-column: 8 / -1;
  }
  @media ${from(Device.DesktopLarge)} {
    grid-column: 7 / -1;
  }
`;
const MobileImage = styled.div `
  height: 250px;

  margin: 10px 0px;
  @media ${from(Device.Tablet)} {
    margin: 15px 0px;
    height: 300px;
  }

  @media ${from(Device.Desktop)} {
    display: none;
  }
`;
const Title = styled.h1 `
  ${fonts.DaxPro.Bold};

  margin-bottom: 10px;
  font-size: 25px;
  line-height: 30px;
  @media ${from(Device.Tablet)} {
    margin-bottom: 15px;
    font-size: 35px;
  }
  @media ${from(Device.Desktop)} {
    margin-bottom: 40px;
    font-size: 40px;
  }
`;
const SubTitle = styled.h3 `
  color: ${brand.primary.alt};

  font-size: 22px;
  line-height: 25px;
  @media ${from(Device.Tablet)} {
    margin-bottom: 30px;
    font-size: 25px;
  }

  @media ${from(Device.Desktop)} {
    margin-bottom: 20px;
    font-size: 28px;
  }
`;
const Text = styled.div `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  letter-spacing: normal;
  line-height: 26px;
  margin-bottom: 16px;
`;
const MotionWrapper = styled(m.div).attrs(() => ({
    variants: {
        hidden: {
            opacity: 0,
            y: 15,
        },
        visible: {
            opacity: 1,
            y: 0,
        },
    },
    transition: {
        duration: 0.7,
    },
})) `
  ${() => ParagraphStyles.Paragraph} {
    margin-bottom: 0;
  }
`;
export const LoginBannerStyles = {
    Container,
    LeftSection,
    ButtonsContainer,
    ImageContainer,
    Title,
    SubTitle,
    Text,
    MobileImage,
    MotionWrapper,
};
